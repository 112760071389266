<template>
    <section class="pqr-solicitudes">
        <cargando v-if="cargando" />
        <div class="row mb-4">
            <div class="col-12 col-lg-auto">
                <p class="f-20 f-500">PQRs resueltas</p>
            </div>
            <div class="col ml-auto my-auto">
                <div class="row justify-content-lg-end d-middle-center">
                    <div class="row justify-content-lg-end">
                        <div class="col-auto mx-2">
                            <div class="row bg-f5 border br-8 indicadores">
                                <div class="col-auto my-auto">
                                    <p class="f-17 f-500">
                                        {{ estadisticas.porcentaje_bueno }}%
                                        <span class="f-100 pl-2 f-17 text-muted">{{ estadisticas.total_bueno }}</span>
                                    </p>
                                </div>
                                <div class="col pr-0">
                                    <div class="indicador3 d-middle-center">
                                        <i class="f-20 icon-like op-05" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto mx-2">
                            <div class="row bg-f5 border br-8 indicadores">
                                <div class="col-auto my-auto">
                                    <p class="f-17 f-500">
                                        {{ estadisticas.porcentaje_malo }}%
                                        <span class="f-100 pl-2 f-17 text-muted">{{ estadisticas.total_malo }}</span>
                                    </p>
                                </div>
                                <div class="col pr-0">
                                    <div class="indicador2 d-middle-center">
                                        <i class="f-20 icon-thumb-down op-05" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <el-input v-model="search" placeholder="Buscar" prefix-icon="el-icon-search" size="small" />
            </div>
        </div>
        <!-- tabla -->
        <div class="row">
            <div class="col-12">
                <el-table :data="resueltas" height="calc(100vh - 215px)" style="width: 100%" @row-click="verResuelta">
                    <el-table-column prop="estado" width="80" align="center">
                        <template slot-scope="scope">
                            <img v-show="scope.row.animo == 1" src="/img/pqr/enojadoAmarillo.svg" alt="" />
                            <img v-show="scope.row.animo == 2" src="/img/pqr/tristeAmarillo.svg" alt="" />
                            <img v-show="scope.row.animo == 3" src="/img/pqr/confuso-1.svg" alt="" />
                            <img v-show="scope.row.animo == 4" src="/img/pqr/felizAmarillo.svg" alt="" />
                            <img v-show="scope.row.animo == 5" src="/img/pqr/enamorado.svg" alt="" />
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="titulo" label="Título">
                        <template slot-scope="scope">
                            {{ scope.row.id }} - {{ scope.row.titulo }}
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="tipo" label="Tipo" width="120">
                        <template slot-scope="scope">
                            <span v-if="scope.row.tipo == 1">Petición</span>
                            <span v-if="scope.row.tipo == 2">Queja</span>
                            <span v-if="scope.row.tipo == 3">Reclamo</span>
                            <span v-if="scope.row.tipo == 4">Sugerencia</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="usuario" label="Solicitante"> 
                        <template slot-scope="scope">
                            <div class="d-flex tres-puntos">
                                <div class="wh-32px rounded-circle d-middle-center bg-azulclaro">
                                    <i class="icon-account-outline f-20" />
                                </div>
                                <p class="f-16 ml-2 my-auto tres-puntos"> {{ scope.row.usuario.nombre }} </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="created_at" label="Fecha" align="center">
                        <template slot-scope="scope">
                            <div class="">
                                <p>{{ scope.row.created_at | helper-fecha('DD/MM/YYYY') }}</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="usuario" label="Vivienda">
                        <template slot-scope="scope">
                            <div class="">
                                <p>{{ scope.row.usuario.vivienda }}</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="updated_at" label="Finalizada" align="center">
                        <template slot-scope="scope">
                            <div class="">
                                <p>{{ scope.row.updated | helper-fecha('DD/MM/YYYY') }}</p>
                            </div>
                        </template>
                    </el-table-column>
                    <div slot="empty" class="row">
                        <div class="col-12 text-center">
                            <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120" />
                            <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No hemos encontrado coincidencias con tu búsqueda</p>
                        </div>
                    </div>
                    <infinite-loading
                    slot="append"
                    force-use-infinite-wrapper=".el-table__body-wrapper"
                    @infinite="getResueltas"
                    />
                </el-table>
            </div>
            <!-- <div class="col-12">
                <infinite-loading @infinite="getResueltas">
                    <div slot="spinner">
                        Consultando...
                    </div>
                    <div slot="no-more" />
                    <div slot="no-results" />
                </infinite-loading>
            </div> -->
        </div>
    </section>
</template>
<script>
import PQRS from '@/services/pqrs/index'
import InfiniteLoading from 'vue-infinite-loading';

export default {
    components:{
        InfiniteLoading
    },
    data(){
        return{
            search:'',
            estadisticas: {},
            resueltas: [],
            page: 1,
            cargando: false
        }
    },
    watch: {
        search(){
            this.buscador(this.search)
        }
    },
    mounted(){
        this.getEstadisticasResueltas()
    },
    methods:{
        verResuelta(row){
            this.$router.push({ name: 'pqr.ver.solicitud', params: {id_pqr: row.id} })
        },
        async getEstadisticasResueltas(){
            try {
                this.cargando = true
                const { data } = await PQRS.getEstadisticas()
                this.estadisticas = data.data
            } catch (error){
                this.errorCatch(error)
            } finally {
                this.cargando = false
            }
        },

        async getResueltas($state){
            try {
                this.cargando = true
                const state = $state

                const params={
                    search : this.search,
                    page: this.page
                }
                const { data } = await PQRS.listarFinalizadas({params})
                if(data.data.data.length){
                    this.page += 1;
                    this.resueltas.push(...data.data.data)
                    if(typeof state !== 'undefined'){
                        state.loaded();
                    }
                }else{
                    if(typeof state !== 'undefined'){
                        state.complete();
                    }
                }
            } catch (error){
                this.errorCatch(error)
            } finally{
                this.cargando = false
            }
        },
        async buscador(search){
            const busqueda = async() => {
                this.search = search;
                this.resueltas = []
                this.page = 1
                this.getResueltas()
            }
            await this.delay(busqueda)
        },
    }
}
</script>
<style lang="scss" scoped>

</style>